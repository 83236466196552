import React, { useEffect, useState } from "react";
import { Modal as AntdModal } from "antd";
import classNames from "classnames";
import { IoClose } from "react-icons/io5";

// interfaces
import { ModalProps } from "./IModal";

// assets
import styles from "@/assets/styles/shared/modal.module.scss";

function Modal(props: ModalProps) {
  const [isClient, setIsClient] = useState(false);
  const {
    children,
    className,
    wrapClassName,
    title,
    closeIcon,
    onCancel,
    closable = true,
    ...rest
  } = props;

  const modalClassName = classNames(styles["locl-modal"], className, {
    "locl-modal_open": rest.open
  });
  const modalWrapClassName = classNames("locl-modal-wrapper", wrapClassName);

  // effects
  useEffect(() => {
    if (typeof window === "undefined") {
      setIsClient(false);
    } else {
      setIsClient(true);
    }
  }, []);

  // renders
  if (isClient) {
    return (
      <AntdModal
        className={modalClassName}
        wrapClassName={modalWrapClassName}
        closeIcon={<div />}
        title={
          <div className={styles["locl-modal__header"]}>
            <div className={styles["locl-modal__header-label"]}>{title}</div>
            {closable ? (
              <span
                onClick={() => onCancel && onCancel()}
                className={classNames(styles["locl-modal__header-icon"], "locl-modal__header-icon")}
                data-testid="locl-modal-close-icon"
              >
                {closeIcon || <IoClose />}
              </span>
            ) : null}
          </div>
        }
        closable={closable}
        onCancel={onCancel}
        {...rest}
      >
        {children}
      </AntdModal>
    );
  }
}

export default Modal;
