import axios from "./axios";
import { pathToUrl } from "./query/reactQuery/pathToUrl";
import API_ROUTES from "./routes.constant";

// interfaces
import { ITokens } from "@/types/api/Tokens";

const { account, modalWindowUrls } = API_ROUTES;


const register = {
  async registerLandlord(userData: Record<string, unknown>) {
    const res = await axios.post(account.registerLandlord, userData);
  
    return res.data.results;
  },
  registerHirer(userData: Record<string, unknown>) {
    return axios.post(account.registerHirer, userData);
  },
  async postLockoutModal(data: Record<string, unknown>) {
    return axios.post(modalWindowUrls.modalWindow, data);
  },
  async pathLockoutModal(id: number, data: Record<string, unknown>) {
    return axios.patch(`${modalWindowUrls.modalWindow}/${id}`, data);
  },
  async registrationModalRegistration(id: number, data: Record<string, unknown>) {
    return axios.patch(`${modalWindowUrls.modalWindow}/${id}`, data);
  },
  async registrationModalSignIn(id: number, data: Record<string, unknown>) {
    return axios.patch(`${modalWindowUrls.modalWindow}/${id}`, data);
  },
  async registerActivateToken({ uid, token, reminder }: { uid: string, token: string, reminder: string }) {
    const fetchKey = (token && uid) ? `${pathToUrl(account.activateAccountToken, { uid, token })}?reminder=${reminder}` : "";

    return axios.get<ITokens>(fetchKey);
  },
  async registerUnsubscribe({ uid, token }: { uid: string, token: string }) {
    const fetchKey = (token && uid) ? pathToUrl(account.registerUnsubscribe, { uid, token }) : "";

    return axios.get(fetchKey);
  }
};

export default register;
