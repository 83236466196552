declare global {
  interface Window {
    dataLayer: Record<string, unknown>[];
  }
}

const customDataLayerPush = (event: string, hirerId: number | null, props = {}) => {
  const customDataLayer = window.dataLayer || [];

  const respObj: { event: string; hirerId?: number } = { event };

  if (hirerId) {
    respObj.hirerId = hirerId;
  }

  customDataLayer.push({
    ...respObj,
    ...props
  });
};

export default customDataLayerPush;
