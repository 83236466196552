import React, { createContext, useContext, useState } from "react";

// components
import LockoutModal from "@/components/Modals/LockoutModal";

// helpers
import useRouter from "@/hooks/useRouter";

interface IStateValue {
  open: boolean;
  type: "soft" | "hard";
  loginCallback: (() => void) | null;
  searchQuery: string | null;
  loginCallbackUrl: string | null;
}

const INITIAL_STATE: IStateValue = {
  open: false,
  type: "soft" as "soft" | "hard",
  loginCallback: null,
  loginCallbackUrl: null,
  searchQuery: null,
};

export interface LockoutModalContextInterface {
  state: typeof INITIAL_STATE;
  setState: (newState: Record<string, unknown>) => void;
  resetState: () => void;
}

export const LockoutModalContext = createContext<LockoutModalContextInterface>({
  state: INITIAL_STATE,
  setState: () => null,
  resetState: () => null,
});

export const useLockoutModalContext = () => {
  const context = useContext(LockoutModalContext);
  if (!context) {
    throw Error("useLockoutModalContext should br used within a LockoutModalContext");
  }

  return context;
};

export function LockoutModalContextProvider({ children }: { children: React.ReactNode }) {
  const [state, setState] = useState<IStateValue>(INITIAL_STATE);

  const router = useRouter();

  // methods
  const handleSetState = (newState: Record<string, unknown>) => {
    if (typeof newState !== "undefined") {
      setState(prevState => ({ ...prevState, ...(newState || {}) }));
    }
  };

  const resetState = () => setState(INITIAL_STATE);

  const onLogin = () => {
    const { loginCallbackUrl, loginCallback } = state;

    if (loginCallbackUrl) {
      return router.push(loginCallbackUrl);
    }
  
    if (loginCallback) {
      return loginCallback();
    }

    return null;
  }

  const onBackSearchClick = () => {
    if (state.searchQuery) {
      router.push(`/search-results?${state.searchQuery}`);
      setState(INITIAL_STATE)

      return null
    }

    return null;
  }

  return (
    <LockoutModalContext.Provider value={{ state, setState: handleSetState, resetState }}>
      { children }
      <LockoutModal
        open={state.open}
        type={state.type as "soft" | "hard"}
        onLogin={onLogin}
        onCancel={() => setState(INITIAL_STATE)}
        onBackSearchClick={onBackSearchClick}
      />
    </LockoutModalContext.Provider>
  );
}
