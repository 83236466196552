import React from "react";

// components
import Modal from "@/shared/Modal";
const LoginForm = React.lazy(() => import("@/components/Auth/LoginForm"));

// helpers
import wording from "@/globals/wording.constants";

// interfaces
import { ILoginModal } from "./ILogInModal";

// assets
import styles from "@/assets/styles/components/Modals/login-modal.module.scss";


function LogInModal({ open, onSubmit, ...props }: ILoginModal) {

  return (
    <Modal
      title={wording.auth.loginModal.title}
      centered
      open={open}
      footer={null}
      forceRender
      className={styles["locl-login-modal"]}
      data-testid="locl-login-modal"
      { ...props }
    >
      <React.Suspense fallback="...">
        { open && (
          <LoginForm
            isRowView
            title={null}
            onSubmit={onSubmit}
            className="locl-login-modal__form"
          />
        )}
      </React.Suspense>
    </Modal>
  );
}

export default LogInModal;
