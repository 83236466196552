"use client";

import React, { useState, useEffect } from "react";
import Image from "next/image";
import get from "lodash/get";
import classNames from "classnames";

// components
import Modal from "@/shared/Modal";
import Button from "@/shared/Button";
import LogInModal from "../AuthModal/LogInModal";
import SignUpModal from "../AuthModal/SignUpModal";

// interfaces
import { ILockoutModal } from "./ILockoutModal";
import { IRegistrationFormValues } from "@/components/Auth/RegistrationForm";

// helpers
import register from "@/api/register";
import countryStateCity from "@/api/query/countryStateCity";
import customDataLayerPush from "@/helpers/customDataLayerPush";
import useRouter from "@/hooks/useRouter";
import getCityQueryListByRegion from "@/helpers/getCityQueryListByRegion";

// assets
import weriLogoVertical from "@/assets/images/landing/logo/weri-logo-vertical.png";
import styles from "@/assets/styles/components/Modals/lockout-modal.module.scss";


const MODAL_KEYS = {
  login: "LOGIN_MODAL",
  signup: "SIGNUP_MODAL",
};

function LockoutModal({ open, onCancel, onLogin, onBackSearchClick, type = "soft", ...props }: ILockoutModal) {
  const modalTypeToUse = process.env.NEXT_PUBLIC_APP_WL_CODE === "LOCL" ? type : "soft";
  
  const { data: countryData } = countryStateCity.useCountriesFilters({ only_published: true });
  
  const [openModalKey, setOpenModalKey] = useState<string | null>(null);
  const [analyticsId, setAnalyticsId] = useState<number | null>(null);

  const LOFO_TEXT = {
    title: "We've got some awesome stuff!",
    description: "Sign up or log in for free, unlimited access to search, plan and book over 1600 spaces in the UK, Europe and Australia.",
  }

  const MODAL_CONFIGS = {
    soft: {
      ...LOFO_TEXT,
      closable: true,
    },
    hard: {
      ...LOFO_TEXT,
      closable: false,
    },
  };

  const { push } = useRouter();

  // methods
  const handleCancel = () => {
    setAnalyticsId(null);
    setOpenModalKey(null);
    onCancel && onCancel();
  };

  const handleClickLogin = () => {
    setOpenModalKey(MODAL_KEYS.login);
    customDataLayerPush("lo_fo_modal_cta_click", null, { lofo_type: `${type} lofo`, cta_type: "Log in" });
  };

  const handleClickRegister = () => {
    setOpenModalKey(MODAL_KEYS.signup);
    customDataLayerPush("lo_fo_modal_cta_click", null, { lofo_type: `${type} lofo`, cta_type: "Sign up" });
  };

  const handleRegister = (data: IRegistrationFormValues) => {
    register.registrationModalRegistration(analyticsId as number, {
      userEmail: data.email,
      hasUserSignedUp: true,
      modalType: modalTypeToUse,
    })
      .then(() => customDataLayerPush("lo_fo_modal_form_submit", null, { lofo_type: `${type} lofo`, cta_type: "Sign up" }))
      .finally(() => onCancel && onCancel());
  };

  const handleSearchClick = async (location: "europe" | "australia") => {
    if (location === "europe") {
      const query = getCityQueryListByRegion({ countryData: countryData || null, region: "europe" });

      push(`/search-results?city__in=${query}`);

      onCancel && onCancel();

      return;
    }

    const query = getCityQueryListByRegion({ countryData: countryData || null, region: "australia" });

    push(`/search-results?city__in=${query}`);

    onCancel && onCancel();
  }

  const handleLogin = (userType: string) => {
    register.registrationModalSignIn(analyticsId as number, { hasUserClickedSignIn: true, modalType: modalTypeToUse, })
      .then(() => customDataLayerPush("lo_fo_modal_form_submit", null, { lofo_type: `${type} lofo`, cta_type: "Log in" }))
      .then(() => {
        if (userType === "hirer") {
          onLogin && onLogin()

          return
        }

        push("/locations");
      })
      .finally(() => {
        setOpenModalKey(null);
        onCancel && onCancel();
      });
  };

  // effects
  useEffect(() => {
    if (!analyticsId && open) {
      register.postLockoutModal({ modalType: modalTypeToUse })
        .then(response => {
          const analyticsIdToSet = get(response, "data.id");
          analyticsIdToSet && setAnalyticsId(analyticsIdToSet);
        })
        .catch(() => null);
    }
  }, [open]);

  // renders
  return (<>
    <Modal
      centered
      open={open}
      footer={null}
      className={classNames(
        styles["locl-lockout-modal"],
        { [styles["locl-lockout-modal_hidden"]]: openModalKey },
      )}
      { ...props }
      title={MODAL_CONFIGS[modalTypeToUse].title}
      closable={MODAL_CONFIGS[modalTypeToUse].closable}
      onCancel={() => {
        MODAL_CONFIGS[modalTypeToUse].closable && handleCancel()
      }}
      zIndex={9999}
    >
      <div className={styles["locl-lockout-modal__description"]} data-testid="locl-lockout-modal-description">
        {MODAL_CONFIGS[modalTypeToUse].description}
      </div>
      <div className={styles["locl-lockout-modal__footer"]}>
        <Button
          fullWidth
          onClick={handleClickRegister}
          type="primary"
          danger
          data-testid="locl-lockout-modal-button-sign-up"
          className={styles["locl-lockout-modal__footer__button-sign-up"]}
        >
          Sign up
        </Button>
        {
          modalTypeToUse === "hard" && (
            <Button
              fullWidth
              onClick={onBackSearchClick}
              data-testid="locl-lockout-modal-button-back-to-search"
            >
              See similar search results
            </Button>
          )
        }
        <Button
          fullWidth
          type={modalTypeToUse === "hard" ? "tertiary" : "primary"}
          onClick={handleClickLogin}
          data-testid="locl-lockout-modal-button-login"
        >
          Log In
        </Button>
        {
          modalTypeToUse === "soft" && process.env.NEXT_PUBLIC_APP_WL_CODE === "LOCL" && (
            <div className={styles["locl-lockout-modal__footer__buttons-search"]}>
              <Button
                fullWidth
                onClick={() => handleSearchClick("europe")}
                type="secondary"
                success
                data-testid="locl-lockout-modal-button-search-europe"
              >
                Search UK and Europe
              </Button>
              <Button
                fullWidth
                onClick={() => handleSearchClick("australia")}
                type="secondary"
                success
                data-testid="locl-lockout-modal-button-search-australia"
              >
                Search Australia
              </Button>
            </div>
          )
        }
        <Button
          to="/book-a-demo"
          fullWidth
          type="tertiary"
          className={styles["locl-lockout-modal__footer__button-more-info"]}
          data-testid="locl-lockout-modal-button-book-demo"
        >
          More information
        </Button>
        { process.env.NEXT_PUBLIC_APP_WL_CODE === "WERI" && (
          <div className={styles["locl-lockout-modal__footer-logo"]} data-testid="locl-lockout-modal-footer-logo">
            <Image
              className={styles["locl-lockout-modal__footer-logo__tag"]}
              src={weriLogoVertical}
              alt="weri logo"
            />
          </div>
        )
        }
      </div>
    </Modal>
    <React.Suspense fallback="...">
      {
        open
          ? (<>
            <SignUpModal
              open={open && openModalKey === MODAL_KEYS.signup}
              onSubmit={handleRegister}
              onCancel={() => setOpenModalKey(null)}
              zIndex={99999}
            />
            <LogInModal
              open={open && openModalKey === MODAL_KEYS.login}
              onSubmit={handleLogin}
              onCancel={() => setOpenModalKey(null)}
              zIndex={99999}
            />
          </>)
          : null
      }
    </React.Suspense>
  </>);
}

export default LockoutModal;
