import React from "react";

// components
import Modal from "@/shared/Modal";
const RegistrationForm = React.lazy(() => import("@/components/Auth/RegistrationForm"));

// helpers
import wording from "@/globals/wording.constants";

// interfaces
import { ISignUpModal } from "./ISignUpModal";

// assets
import styles from "@/assets/styles/components/Modals/sign-up-modal.module.scss";


function SignUpModal({ open, onSubmit, ...props }: ISignUpModal) {

  return (
    <Modal
      title={wording.auth.signUp.title}
      centered
      open={open}
      footer={null}
      forceRender
      className={styles["locl-sign-up-modal"]}
      data-testid="locl-sign-up-modal"
      { ...props }
    >
      <React.Suspense fallback="...">
        { open && (
          <RegistrationForm
            title={null}
            subtitle={null}
            className="locl-sign-up-modal__form"
            onSubmit={onSubmit}
            isRedirectToThankYouPage
            isShowContinueButton
          />
        ) }
      </React.Suspense>
    </Modal>
  );
}

export default SignUpModal;
