// interfaces
import { ICountryResp } from "@/types/api/CountryStateCity";

interface IGetCityQueryListByRegion {
  countryData: ICountryResp[] | null;
  region: "europe" | "australia"
}

const handleCreateCityQueryByCountry = (countryList: ICountryResp[]) => {
  return countryList.map(
    country => country.regions.map(
      region => region.cities.map(
        city => city.id
      ).join("__")
    ).join("__")
  ).join("__");
};

const getCityQueryListByRegion = ({ countryData, region }: IGetCityQueryListByRegion) => {
  if (region === "europe") {
    const countryList = (countryData || []).filter(({ name }) => name !== "Australia");

    return handleCreateCityQueryByCountry(countryList);
  }

  const countryList = (countryData || []).filter(({ name }) => name === "Australia");

  return handleCreateCityQueryByCountry(countryList);
}

export default getCityQueryListByRegion;
